import {
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { BLACK } from 'theme/ui-palette';
import { useAuth0 } from '@auth0/auth0-react';
import { useListOrganizations } from 'queries/organizations/useListOrganizations';
import { ChangeEvent, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';

export function TenantSwitcher() {
  const { getAccessTokenSilently, user } = useAuth0();
  const { data: organizations } = useListOrganizations();

  const currentOrg = useMemo(() => {
    return organizations?.find(
      (org) => org.auth0OrganizationId === user?.org_id
    );
  }, [organizations, user]);

  const switchTenant = useCallback(
    async (auth0OrganizationName: string) => {
      const newOrg = organizations?.find(
        (org) => org.auth0OrganizationName === auth0OrganizationName
      );
      if (!newOrg || newOrg?.id === currentOrg?.id) return;
      await getAccessTokenSilently({
        organization: newOrg.auth0OrganizationId,
        ignoreCache: true,
      });
      window.location.href = '/';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizations]
  );

  const handleChange = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        switchTenant(e.target.value);
      }, 1000),
    [switchTenant]
  );

  return currentOrg ? (
    <Popover isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              aria-label="Tenant Switcher"
              px={3}
              variant="ghost"
              rightIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {currentOrg.name}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody py={3}>
              <Text mb={1} fontWeight="bold" color={BLACK}>
                Current: {currentOrg.auth0OrganizationName}
              </Text>
              <Input
                variant="outline"
                placeholder="Organization Code"
                onChange={handleChange}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  ) : null;
}
