const env = process.env;

export type Config = {
  baseUrl: string;
  auth0: {
    domain: string;
    audience: string;
    clientId: string;
  };
  featureToggles: {
    enableSchedulePurchaseOrder: boolean;
    enableCreateSalesOrder: boolean;
    enableDataDogRUM: boolean;
    enableDataDogLogs: boolean;
    enableAsnReport: boolean;
    enableTransportationDetailsReport: boolean;
    enableReturnsReport: boolean;
    enableItemMaster: boolean;
  };
  envBanner: string | undefined;
  googleAnalyticsId: string;
  env: 'UAT' | 'TEST' | 'DEV' | 'PROD';
  bannerMessage: string;
  contactEmails: string;
  rateShopInternationalTenant: string[];
  helpCenterUrl: string;
};

const appEnv =
  env.REACT_APP_ENV_BANNER === 'UAT'
    ? 'UAT'
    : env.NODE_ENV === 'development'
    ? 'DEV'
    : process.env.JEST_WORKER_ID !== undefined
    ? 'TEST'
    : 'PROD';

export const CONFIG = (): Config => ({
  baseUrl: env.REACT_APP_API_BASE_URL ?? '',
  auth0: {
    domain: env.REACT_APP_AUTH0_DOMAIN ?? '',
    audience: env.REACT_APP_AUTH0_AUDIENCE ?? '',
    clientId: env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  },
  featureToggles: {
    enableSchedulePurchaseOrder: isFeatureFlagEnabled(
      'REACT_APP_FEATURE_SCHEDULE_PO_ENABLE',
      'schedule_po'
    ),
    enableCreateSalesOrder: isFeatureFlagEnabled(
      'REACT_APP_FEATURE_CREATE_SO_ENABLE',
      'create_so'
    ),
    enableDataDogRUM:
      appEnv === 'PROD' ||
      isFeatureFlagEnabled('REACT_APP_FEATURE_DATADOG_RUM', 'datadog_rum'),
    enableDataDogLogs:
      appEnv === 'PROD' ||
      isFeatureFlagEnabled('REACT_APP_FEATURE_DATADOG_LOGS', 'datadog_logs'),
    enableAsnReport: isFeatureFlagEnabled(
      'REACT_APP_FEATURE_ASN_REPORT_ENABLE',
      'asn_report'
    ),
    enableTransportationDetailsReport: isFeatureFlagEnabled(
      'REACT_APP_FEATURE_TRANSPORTATION_DETAILS_REPORT_ENABLE',
      'transportation_details_report'
    ),
    enableReturnsReport: isFeatureFlagEnabled(
      'REACT_APP_FEATURE_RETURNS_REPORT_ENABLE',
      'returns_report'
    ),
    enableItemMaster: isFeatureFlagEnabled(
      'REACT_APP_FEATURE_ITEMMASTER_ENABLE',
      'item_master'
    ),
  },
  envBanner: env.REACT_APP_ENV_BANNER ?? undefined,
  googleAnalyticsId: env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '',
  env: appEnv,
  bannerMessage: env.REACT_APP_BANNER_MESSAGE ?? '',
  contactEmails: env.REACT_APP_CONTACT_EMAILS ?? '{}',
  rateShopInternationalTenant: env.REACT_APP_RATE_SHOP_INTERNATIONAL_TENANT
    ? env.REACT_APP_RATE_SHOP_INTERNATIONAL_TENANT.split(',')
    : [],
  helpCenterUrl: env.REACT_APP_HELP_CENTER_URL ?? '',
});

/**
 * This is a very limited implementation of a feature flags mechanism.
 * It's intended to be temporary and should be replaced by an actual
 * FF service ASAP (e.g. Optimizely or LaunchDarkly).
 */

function isFeatureFlagEnabled(envVarName: string, flagName: string) {
  const isFlagEnabledOnSession =
    window.sessionStorage.getItem(`ff.${flagName}`) === 'enabled';
  const isFlagEnabledOnEnvVar =
    (env[envVarName] ?? 'false').toLowerCase() === 'true';

  return isFlagEnabledOnEnvVar || isFlagEnabledOnSession;
}

function checkFeatureFlagsOnUrl() {
  const params = new URLSearchParams(window.location.search);
  const flagsToEnable = params.get('enable_flags')?.split(',') ?? [];
  const flagsToDisable = params.get('disable_flags')?.split(',') ?? [];

  flagsToDisable.forEach((flag) =>
    window.sessionStorage.removeItem(`ff.${flag}`)
  );
  flagsToEnable.forEach((flag) =>
    window.sessionStorage.setItem(`ff.${flag}`, 'enabled')
  );

  if (flagsToEnable.length || flagsToDisable.length) {
    window.location.href = '/';
  }
}

checkFeatureFlagsOnUrl();

if (appEnv !== 'TEST') {
  console.info(`Current environment: ${appEnv}`);
  console.info('Feature flags: ');
  console.table(CONFIG().featureToggles);
}
