import { mapDtoToStorefront } from 'queries/tenant/mappers';
import {
  FreightSalesOrder,
  FreightSalesOrderCondensedDto,
  FreightSalesOrderDetailDto,
  ParcelSalesOrder,
  SalesOrder,
  SalesOrderCommon,
  SalesOrderCondensedDto,
  SalesOrderDto,
  SalesOrderLineBaseDto,
  SalesOrderSearchResult,
  SalesOrderSearchResultDto,
  SalesOrderStatus,
  SalesOrderSummary,
  SalesOrderSummaryDto,
  SalesOrderLineBase,
  ParcelSalesOrderDetailDto,
  ParcelSalesOrderCondensedDto,
  SalesOrderTrackingNumbersDetail,
} from 'types/salesOrders';

export function mapDtoToSalesOrder(order: SalesOrderDto): SalesOrder {
  return order.processing_type === 'Freight'
    ? mapDtoToFreightSalesOrder(order)
    : mapDtoToParcelSalesOrder(order);
}

export function mapDtoToOrderSummary(
  summary: SalesOrderSummaryDto
): SalesOrderSummary {
  return {
    totalPending: summary.total_pending,
    totalReceived: summary.total_received,
    totalStarted: summary.total_started,
    totalWaiting: summary.total_waiting,
    totalExceptions: summary.total_exceptions,
  };
}

function mapOrderLineBase(line: SalesOrderLineBaseDto): SalesOrderLineBase {
  return {
    lineNo: line.line_number,
    bundleSku: line.parent_bundle_sku ?? '',
    sku: line.sku,
    customerItemNumber: line.external_item_number ?? '',
    unitOfMeasure: line.uom,
    description: line.item_description,
    shipDate: line.latest_ship_date,
    orderQty: line.order_qty,
    pickedQty: line.picked_qty,
    shippedQty: line.ship_qty,
    shortQty: line.short_qty,
    trackingNumbers: line.tracking_numbers,
    unpickedQty: line.unpicked_qty,
    canceledQty: line.canceled_qty,
  };
}

function mapDtoToFreightSalesOrder(
  order: FreightSalesOrderCondensedDto | FreightSalesOrderDetailDto
): FreightSalesOrder {
  const isCondensed = isCondensedSalesOrder(order);

  const mappedOrder: FreightSalesOrder = {
    ...mapDtoToSalesOrderCommon(order),
    type: 'Freight',
    lines: isCondensed
      ? []
      : order.sales_order_lines.map((line) => ({
          ...mapOrderLineBase(line),
          customerShipmentReference: line.customer_shipment_reference,
          arn: line.arn,
          asn: line.asn,
        })),
    bolData: null,
  };

  if (!isCondensed) {
    const bolData = order.bol_data;
    const billTo = bolData?.bill_to_address;

    mappedOrder.arn = order.arn;
    mappedOrder.asn = order.asn;
    mappedOrder.printGtin14Label = order.print_gtin14_label;
    mappedOrder.printUpcLabel = order.print_upc_label;
    mappedOrder.bolData = bolData
      ? {
          type: bolData.bol_type,
          bolNumber: bolData.bol_number,
          shipToCidNumber: bolData.ship_to_cid_number,
          shipToLocationNumber: bolData.ship_to_location_number,
          fob: bolData.fob,
          billingAddress: billTo
            ? {
                name: billTo.name,
                address: billTo.address,
                address2: billTo.address_2 ?? '',
                city: billTo.city,
                state: billTo.state,
                zipCode: billTo.zip_code,
                country: billTo.country,
                email: billTo.email,
                phoneNumber: billTo.phone,
              }
            : null,
        }
      : null;
  }

  return mappedOrder;
}

function mapDtoToParcelSalesOrder(
  order: ParcelSalesOrderDetailDto | ParcelSalesOrderCondensedDto
): ParcelSalesOrder {
  const mappedOrder = mapDtoToSalesOrderCommon(order);
  const isCondensed = isCondensedSalesOrder(order);

  return {
    ...mappedOrder,
    type: 'Parcel',
    shipOption: order.ship_option || '',
    lines: isCondensed
      ? []
      : order.sales_order_lines.map((line) => mapOrderLineBase(line)),
  };
}

function mapDtoToSalesOrderCommon(order: SalesOrderDto): SalesOrderCommon {
  const isCondensed = isCondensedSalesOrder(order);
  const lines = isCondensed ? [] : order.sales_order_lines;
  const status = order.latest_status || 'Received';

  const statusDate = isCondensed
    ? order.status_date
    : order.status_history.at(-1)?.status_date || '';

  const totalLines = isCondensed
    ? order.total_lines
    : order.sales_order_lines.length;

  const statusChangesHistory = isCondensed
    ? []
    : order.status_history.map(({ status, status_date }) => ({
        status: status as SalesOrderStatus,
        status_date: status_date,
      }));

  const totalOrderQty = isCondensed
    ? order.total_order_qty
    : calculateTotal(lines, 'order_qty');

  const totalPickedQty = isCondensed
    ? order.total_picked_qty
    : calculateTotal(lines, 'picked_qty');

  const totalShippedQty = isCondensed
    ? order.total_shipped_qty
    : calculateTotal(lines, 'ship_qty');

  const totalShortQty = isCondensed
    ? order.total_short_qty
    : calculateTotal(lines, 'short_qty');

  const totalUnpickedQty = isCondensed
    ? order.total_unpicked_qty
    : calculateTotal(lines, 'unpicked_qty');

  const totalCanceledQty = isCondensed
    ? order.total_canceled_qty
    : calculateTotal(lines, 'canceled_qty');

  return {
    soNumber: order.so_number,
    poNumber: order.po_number,
    customerName: order.ship_to_address.name,
    customerNumber: order.customer_order_reference,
    shipVia: order.ship_via,
    dueDate: order.due_date,
    earliestDueDate: order.earliest_due_date,
    latestDueDate: order.latest_ship_date,
    instructions: order.instructions,
    totalLines,
    totalOrderQty,
    totalPickedQty,
    totalShippedQty,
    totalShortQty,
    totalUnpickedQty,
    totalCanceledQty,
    createdDate: order.created_date,
    shipDate: order.latest_ship_date,
    status: status as SalesOrderStatus,
    statusDate,
    warehouseID: order.target_dc,
    statusChangesHistory,
    shipFrom: {
      name: order.ship_from_address.name,
      address: order.ship_from_address.address,
      city: order.ship_from_address.city,
      state: order.ship_from_address.state,
      zipCode: order.ship_from_address.zip_code,
    },
    shipTo: {
      name: order.ship_to_address.name,
      address: order.ship_to_address.address,
      address2: order.ship_to_address.address_2 ?? '',
      city: order.ship_to_address.city,
      state: order.ship_to_address.state,
      zipCode: order.ship_to_address.zip_code,
      country: order.ship_to_address.country,
      email: order.ship_to_address.email ?? '',
      phoneNumber: order.ship_to_address.phone_number ?? '',
    },
    carrierAccountUUID: order.carrier_account_uuid || '',
    carrierScacCode: order.carrier_scac_code || '',
    targetDc: order.target_dc,
    trackingNumbers: order.tracking_numbers,
    trackingNumbersDetail: order.tracking_numbers_detail?.map((detail) => ({
      customerShipmentReference: detail.customer_shipment_reference,
      soLineNumber: detail.so_line_number,
      trackingNumbers: detail.tracking_numbers,
      shipmentDetails: detail.shipment_details?.map((shipmentDetail) => ({
        trackingNumber: shipmentDetail.tracking_number,
        service: shipmentDetail.service,
        carrier: shipmentDetail.carrier,
      })),
    })),
    giftMessage: order.gift_message,
    shippingBoxesDetail: order.shipping_boxes_detail?.map((detail) => ({
      packageNumber: detail.package_number,
      trackingNumber: detail.tracking_number,
      items: detail.items,
    })),
    storefront: order.storefront
      ? mapDtoToStorefront(order.storefront)
      : undefined,
    storefrontName: order.storefront?.name,
  };
}

export function buildEmptyOrder(): ParcelSalesOrder &
  Omit<FreightSalesOrder, 'type'> {
  return {
    type: 'Parcel',
    shipOption: '',
    soNumber: '',
    poNumber: '',
    customerName: '',
    customerNumber: '',
    shipVia: '',
    latestDueDate: '',
    instructions: '',
    totalLines: 1,
    totalOrderQty: 0,
    totalPickedQty: 0,
    totalShippedQty: 0,
    totalShortQty: 0,
    totalUnpickedQty: 0,
    totalCanceledQty: 0,
    createdDate: '',
    shipDate: '',
    status: SalesOrderStatus.Received,
    statusDate: '',
    warehouseID: '',
    statusChangesHistory: [],
    shipFrom: {
      name: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
    shipTo: {
      name: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      email: '',
      phoneNumber: '',
    },
    carrierAccountUUID: '',
    carrierScacCode: '',
    lines: buildInittialLines(),
    arn: '',
    asn: '',
    printGtin14Label: false,
    printUpcLabel: false,
    bolData: {
      type: '',
      bolNumber: '',
      shipToCidNumber: '',
      shipToLocationNumber: '',
      fob: false,
      billingAddress: {
        name: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        email: '',
        phoneNumber: '',
      },
    },
    targetDc: '',
    giftMessage: '',
    trackingNumbers: [],
  };
}

export function getFirstShipmentDetails(
  trackingNumbersDetail: SalesOrderTrackingNumbersDetail[] | undefined
) {
  return trackingNumbersDetail?.length
    ? trackingNumbersDetail[0].shipmentDetails?.length
      ? trackingNumbersDetail[0].shipmentDetails[0]
      : undefined
    : undefined;
}

export function buildInittialLines(): SalesOrderLineBase[] {
  return [
    {
      lineNo: '1',
      customerItemNumber: '',
      sku: '',
      unitOfMeasure: '',
      description: '',
      shipDate: '',
      orderQty: 1,
      pickedQty: 0,
      shippedQty: 0,
      shortQty: 0,
      trackingNumbers: [],
      unpickedQty: 0,
      canceledQty: 0,
    },
  ];
}

export function mapDtoToSalesOrderSearchResult(
  order: SalesOrderSearchResultDto
): SalesOrderSearchResult {
  return {
    soNumber: order.so_number,
  };
}

function calculateTotal(
  lines: SalesOrderLineBaseDto[],
  propertyName:
    | 'order_qty'
    | 'picked_qty'
    | 'ship_qty'
    | 'short_qty'
    | 'unpicked_qty'
    | 'canceled_qty'
) {
  return lines.map((l) => l[propertyName]).reduce((a, b) => a + b, 0);
}

function isCondensedSalesOrder(
  order: SalesOrderDto
): order is SalesOrderCondensedDto {
  return (order as SalesOrderCondensedDto).total_lines !== undefined;
}
