import { useMemo, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Text, UnorderedList, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { NavMenuItem } from './NavMenuItem';
import { SlideMenu } from './SlideMenu';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import { CONFIG } from 'utils/config';
import { AUTH0_ROLES } from 'constants/auth0Roles';

type SlideMenuProps = {
  isMainMenuOpen: boolean;
  closeMainMenu: () => void;
};

export type MenuItem = {
  text: string;
  pathname?: string;
  href?: string;
  onClick?: () => void;
  children?: MenuItem[];
  dropdownItems?: MenuItem[];
  isActive?: boolean;
  enabled?: boolean;
};

export type TemplateItem = {
  text: string;
  pathname: string;
};

const SUB_MENU_KEY = 'subMenu';

export const NavMenu = ({ isMainMenuOpen, closeMainMenu }: SlideMenuProps) => {
  const { user } = useAuth0();
  const location = useLocation();
  const subMenuSession = sessionStorage.getItem(SUB_MENU_KEY) ?? '';
  const [subMenu, setSubMenu] = useState(subMenuSession);
  const [visiblePane] = useState<'main' | 'support' | 'templates'>('main');
  const isAdminEnabled = useAuth0Roles();
  const isFinancialDataAccessRole = useAuth0Roles([
    AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
  ]);

  const isActivePath = useCallback(
    (pathname: string | undefined) => {
      return pathname ? location.pathname.startsWith(pathname) : false;
    },
    [location.pathname]
  );

  const handleClickMenuItem = useCallback(
    (menuItem: MenuItem) => {
      if (menuItem.children) {
        const newSubMenu = subMenu !== menuItem.text ? menuItem.text : '';
        sessionStorage.setItem(SUB_MENU_KEY, newSubMenu);
        if (
          newSubMenu === '' &&
          menuItem.children?.some((subMenuItem) =>
            isActivePath(subMenuItem.pathname)
          )
        ) {
          return;
        }
        setSubMenu(newSubMenu);
      } else {
        sessionStorage.setItem(SUB_MENU_KEY, '');
        closeMainMenu();
      }
    },
    [closeMainMenu, subMenu, isActivePath]
  );

  const menuItems: MenuItem[] = useMemo(() => {
    let menuItems: (MenuItem | null)[] = [
      {
        text: 'Dashboard',
        pathname: '/dashboard',
      },
      {
        text: 'Sales Orders',
        pathname: '/orders',
      },
      {
        text: 'Purchase Orders',
        pathname: '/inbound-inventory',
      },
      {
        text: 'Inventory',
        pathname: '/inventory',
      },
      {
        text: 'Item Master',
        enabled: CONFIG().featureToggles.enableItemMaster,
        pathname: '/item-masters',
      },
      {
        text: 'Reports',
        enabled:
          CONFIG().featureToggles.enableAsnReport ||
          CONFIG().featureToggles.enableReturnsReport ||
          (CONFIG().featureToggles.enableTransportationDetailsReport &&
            (isFinancialDataAccessRole || isAdminEnabled)),
        children: [
          {
            text: 'ASN',
            enabled:
              subMenu === 'Reports' && CONFIG().featureToggles.enableAsnReport,
            pathname: '/report/asns',
          },
          {
            text: 'Transportation Details',
            enabled:
              subMenu === 'Reports' &&
              CONFIG().featureToggles.enableTransportationDetailsReport &&
              (isFinancialDataAccessRole || isAdminEnabled),
            pathname: '/report/transportation-details',
          },
          {
            text: 'Returns',
            pathname: '/report/returns',
            enabled:
              subMenu === 'Reports' &&
              CONFIG().featureToggles.enableReturnsReport,
          },
        ] as MenuItem[],
      },
      {
        text: 'Admin',
        pathname: '/admin',
        enabled: isAdminEnabled,
      },
      {
        text: 'Support',
        children: [
          {
            text: 'Templates',
            pathname: '/templates',
            enabled: subMenu === 'Support',
          },
          {
            text: 'Help Center',
            href: CONFIG().helpCenterUrl,
            enabled: subMenu === 'Support',
          },
        ],
      },
      {
        text: 'MSAs',
        pathname: '/msa',
        enabled: isAdminEnabled || isFinancialDataAccessRole,
      },
    ];

    let validMenuItems = menuItems.filter(
      (item) => item !== null && (item.enabled || item.enabled === undefined)
    ) as MenuItem[];

    return validMenuItems.map((menuItem) => {
      return {
        onClick: () => handleClickMenuItem(menuItem),
        isActive: isActivePath(menuItem.pathname),
        ...menuItem,
        children: menuItem?.children?.map((child) => ({
          ...child,
          isActive: isActivePath(child.pathname),
        })),
      };
    });
  }, [
    isAdminEnabled,
    isFinancialDataAccessRole,
    isActivePath,
    subMenu,
    handleClickMenuItem,
  ]);

  useEffect(() => {
    if (subMenu) return;
    const subMenus = menuItems.filter((menuItem) => menuItem.children) || [];
    const activeSubMenu = subMenus.find((subMenuItem) => {
      return subMenuItem.children?.some((subMenuChild) =>
        isActivePath(subMenuChild.pathname)
      );
    });
    if (!activeSubMenu) return;

    setSubMenu(activeSubMenu.text);
    sessionStorage.setItem(SUB_MENU_KEY, activeSubMenu.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlideMenu
      justifyContent="space-between"
      isOpen={isMainMenuOpen}
      closeMenu={closeMainMenu}
    >
      <ErrorBoundary size="compact">
        {visiblePane === 'main' && (
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Text fontWeight={400}>{user?.email}</Text>
              <UnorderedList
                ml={0}
                mt={3}
                role="menu"
                listStyleType="none"
                userSelect="none"
              >
                {menuItems.map((menuItem) => {
                  return (
                    <NavMenuItem
                      key={menuItem.text}
                      aria-current={menuItem.isActive ? 'page' : 'false'}
                      menuItem={menuItem}
                      isCollapsed={subMenu === menuItem.text}
                    >
                      {menuItem.children && (
                        <UnorderedList
                          ml={10}
                          role="submenu"
                          listStyleType="none"
                        >
                          {menuItem.children
                            .filter(
                              (childMenuItemFilter) =>
                                childMenuItemFilter.enabled ||
                                childMenuItemFilter.enabled === undefined
                            )
                            .map((childMenuItem) => (
                              <NavMenuItem
                                key={childMenuItem.text}
                                aria-current={
                                  childMenuItem.isActive ? 'page' : 'false'
                                }
                                menuItem={childMenuItem}
                                isSubMenu={true}
                              />
                            ))}
                        </UnorderedList>
                      )}
                    </NavMenuItem>
                  );
                })}
              </UnorderedList>
            </Box>
          </Flex>
        )}
      </ErrorBoundary>
    </SlideMenu>
  );
};
